<template>
  <div>
    <div v-if="loading" class="text-center mt-8">
      <LoadingSpinner color="primary" class="mb-8"></LoadingSpinner>
    </div>

    <div v-else class="overflow-y-auto">
      <div class="primary r---hero-container">
        <CategoryBanners v-if="offers.length > 0" :offers="offers" />
        <v-container v-if="feature_page_links.length > 0" class="r---promotion">
          <v-row class="r---promotion-row">
            <v-col
              :cols="12 / feature_page_links.length"
              v-for="link in feature_page_links"
              :key="link.target"
              class="r---promotion-column"
            >
              <v-card
                :to="link.target"
                block
                class="flex-column r---promotion-card-outer"
              >
                <v-card-text
                  class="d-flex flex-column align-center r---promotion-card"
                >
                  <v-icon color="primary" class="mb-1 r---promotion-icon">{{
                    link.icon
                  }}</v-icon>
                  <span class="r---promotion-title">{{ $t(link.text) }}</span>
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
        </v-container>
      </div>

      <v-container>
        <v-row v-if="items.length > 0" class="r---grid-row">
          <v-col
            v-for="item in items"
            :key="item.id"
            cols="6"
            class="r---grid-column"
          >
            <CatalogCard v-bind="item" />
          </v-col>
        </v-row>
        <template v-else>
          <div class="mx-auto text-center my-8">
            {{ $t("catalog_page_empty") }}
          </div>
        </template>
      </v-container>
    </div>
  </div>
</template>

<script>
import CatalogCard from "@/components/catalog_card";
import CategoryBanners from "@/components/category_banners";
import LoadingSpinner from "@/components/loading_spinner";
import _ from "underscore";

export default {
  components: {
    CatalogCard,
    CategoryBanners,
    LoadingSpinner,
  },

  data() {
    return {
      products: [],
      promotions_active: false,
      promotions_active_loading: true,
      stokvel_active: false,
      stokvel_active_loading: true,
    };
  },

  computed: {
    categories() {
      return _.sortBy(this.$store.state.root_categories.categories, "name");
    },

    items() {
      return _.sortBy(this.categories.concat(this.products), "name");
    },

    offers() {
      return this.$store.state.category_banners.offers;
    },

    supplier_id() {
      return this.$store.state.suppliers.current_id;
    },

    loading() {
      return (
        this.$store.state.root_categories.loading ||
        this.$store.state.category_banners.loading ||
        !!this.promotions_active_loading ||
        this.stokvel_active_loading
      );
    },

    feature_page_links() {
      const links = [];
      if (this.$store.getters["application/is_agency"]) {
        if (this.promotions_active) {
          links.push({
            target: "/leaflets_promotions",
            text: "catalog_page_promotions",
            icon: "fas fa-star",
          });
        }
        if (this.stokvel_active) {
          links.push({
            target: "/stokvel",
            text: "catalog_page_stokvel",
            icon: "fas fa-calendar",
          });
        }
        if (this.$store.state.application.features.combos) {
          links.push({
            target: "/combos",
            text: "catalog_page_combos",
            icon: "fas fa-boxes",
          });
        }
      } else if (!this.$store.getters["suppliers/current"]?.id) {
        links.push({
          target: "/leaflets",
          text: "catalog_page_leaflets",
          icon: "fas fa-star",
        });
        links.push({
          target: "/agencies",
          text: "catalog_page_stores",
          icon: "fas fa-store",
        });
      }
      return links;
    },
  },

  created() {
    this.fetch_content();
    this.$store.commit("layout/page_nav", {
      title: this.$t("catalog_page_header"),
    });
  },

  destroyed() {
    this.$store.commit("root_categories/clear");
    this.$store.commit("category_banners/clear");
  },

  updated() {
    this.$store.commit("bootstate/page_loaded");
  },

  methods: {
    fetch_content() {
      this.$store.dispatch("root_categories/fetch");
      this.fetch_root_products();

      if (!!this.supplier_id) {
        this.$store.dispatch("category_banners/fetch", {
          category_id: "root",
          supplier_id: this.supplier_id,
        });
        this.$store
          .dispatch("offers/promotions_active")
          .then((active) => (this.promotions_active = active))
          .catch(() => (this.promotions_active = false))
          .finally(() => (this.promotions_active_loading = false));
        this.$store
          .dispatch("offers/stokvel_active")
          .then((active) => (this.stokvel_active = active))
          .catch(() => (this.stokvel_active = false))
          .finally(() => (this.stokvel_active_loading = false));
      } else {
        this.promotions_active_loading = false
        this.stokvel_active_loading = false
      }
    },

    fetch_root_products() {
      this.$store
        .dispatch("product/fetch_root_products")
        .then((products) => {
          this.products = _.map(products, (p) => {
            p.target = "product";
            return p;
          });
        })
        .catch((error) => console.error(error));
    },
  },

  watch: {
    supplier_id(v) {
      this.fetch_content();
    },
  },
};
</script>

<style scoped lang="scss">
.v-btn.flex-column {
  .v-btn__content {
    flex-direction: column;
  }
}
</style>
